<template>
  <div>
    <el-dialog
      title="设置权限"
      :visible.sync="isShow"
      width="600px"
      :before-close="handleClose"
      custom-class="add-user"
      center
      @open="openFn"
    >
      <div class="content">
        <el-form :model="formData" ref="form" :rules="rules" label-position="top">
          <el-form-item label="" prop="" class="tree-box">
            <el-tree
              :data="treeData"
              show-checkbox
              ref="tree"
              node-key="id"
              :props="defaultProps"
              :default-checked-keys="defaultKeys"
            >
            </el-tree>
          </el-form-item>
        </el-form>
        <div class="btn-wrap">
          <el-button @click="submit" :loading="submiting">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { reqUserMenuTree,reqEditUserMenuTree } from "@/api/hy";
import Vue from 'vue'
import * as types from "@/store/action-types";
export default {
  data () {
    return {
      title: '添加员工',
      formData: {},
      rules: {},
      checkList: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      defaultKeys: [],
      submiting: false
    }
  },
  props: {
    isShow: {
      type: Boolean
    },
    data: {
      type: Object
    }
  },
  mounted () {
    this.getDetail({userId: this.data.userId})
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    openFn () {
      console.log(this.data)
      if (this.data.userId) {
        this.getDetail({userId: this.data.userId})
      }
    },
    getDetail (data) {
      reqUserMenuTree(data).then((ret)=>{
        console.log(ret)
        if (ret.code == 0) {
          this.treeData = ret.data.children
          this.setDefaultKeys(this.treeData)
        }
      })
    },
    setDefaultKeys (list) {
      let keyList = []
      let formatTree = function(list) {
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          if (item.privilege == 0) {
            keyList.push(item.id)
          }
          if (item.children && item.children.length > 0) {
            formatTree(item.children);
          }
        }
      };
      formatTree(list);
      this.defaultKeys = keyList
    },
    getMenuList () {
      let nlist = [...this.$refs.tree.getCheckedKeys()];
      nlist = [...new Set(nlist)];
      return nlist;
    },
    submit () {
      if (this.submiting) return
      this.submiting = true;
      let data = {
        userId: this.data.userId,
        menuIdList: this.getMenuList()
      }
      reqEditUserMenuTree(data).then((ret)=>{
        console.log(ret)
        if (ret.code == 0) {
        this.$message({
          type: "success",
          message: "操作成功"
        });
        this.handleClose()
        this.refreshList()
      } else {
        this.$message({
          type: "error",
          message: ret.message,
        });
      }
      this.submiting = false;
      }).catch((err)=>{
        console.log(err)
      })
    },
    refreshList () {
      this.$parent.search.pageNum = 1
      this.$parent.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-wrap{
  text-align: center;
}
.tree-box{
  height: 300px;
  overflow: auto;
}
</style>